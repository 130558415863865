import React from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import * as S from './styles';
const mediaData = [
  {
    title: 'A ORIGEM DO CLIPE DE “BAIANA”, DO EMICIDA',
    source: 'RED BULL',
    imageUrl: '/assets/midia/red-bull.jpg',
    url: 'https://www.redbull.com/br-pt/a-origem-do-clipe-de-baiana-do-emicida', // Exemplo de URL
  },
  {
    title: 'A CRIATIVIDADE É A CHAVE QUE VIRA DE FORMA MUITO RELEVANTE NA PAN',
    source: 'ESCOLA PANAMERICANA DE ARTE E DESIGN',
    imageUrl: '/assets/midia/panamericana.jpg',
    url: 'https://www.escola-panamericana.com.br/a-criatividade-e-a-chave-que-vira-de-forma-muito-relevante-na-pan-diz-moysah/', // Exemplo de URL
  },
  {
    title:
      'BARRY COMPANY ACABA DE ANUNCIAR A CHEGADA DE MOYSAH COMO DIRETOR DE CENA',
    source: 'CLUBE DE CRIAÇÃO',
    imageUrl: '/assets/midia/clube.jpg',
    url: 'https://www.clubedecriacao.com.br/ultimas/producao-1008/', // Exemplo de URL
  },
  // ... adicione mais recursos de mídia conforme necessário
];

const OnMedia = ({ id }) => {
  // Função para abrir URL em uma nova aba
  const openMediaLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <S.OnMediaWrapper id={id}>
      <S.Title>MOYSAH NA MÍDIA</S.Title>
      {mediaData.map((media, index) => (
        <S.MediaFeature key={index} onClick={() => openMediaLink(media.url)}>
          <S.MediaImage src={media.imageUrl} alt={media.title} />
          <S.SearchIconOverlay>
            <IoSearchOutline />
          </S.SearchIconOverlay>
          <S.MediaContent>
            <S.MediaTitle>{media.title}</S.MediaTitle>
            <S.MediaSource>{media.source}</S.MediaSource>
          </S.MediaContent>
        </S.MediaFeature>
      ))}
    </S.OnMediaWrapper>
  );
};

export default OnMedia;
