import React from 'react';
import * as S from './styles';

const TextPanelComponent = ({ id }) => {
  return (
    <S.TextPanel id={id}>
      <S.TopImage src='/assets/banner/about.jpg' alt='Main visual' />

      <S.ContentBox>
        <S.Heading>
          A IMPORTÂNCIA DO <span>AUDIOVISUAL</span> NA INDÚSTRIA DA{' '}
          <span>MÚSICA</span>
        </S.Heading>
        <S.Content>
          O crescimento do consumo de conteúdo audiovisual, somado a expansão e
          alcance das plataformas de streaming promove uma integração do
          audiovisual com a experiência musical. Boa parte da interação e
          engajamento direta do público com a arte do artista é no conteúdo
          audiovisual como os videoclipes, além disso é uma ótima ferramenta de
          promoção e marketing.s
        </S.Content>
      </S.ContentBox>
    </S.TextPanel>
  );
};

export default TextPanelComponent;
