import styled from 'styled-components';

export const ImageBanner = styled.img`
  width: 70%;
  height: auto;
`;

export const Wrapper = styled.div`
  background-image: url('/assets/banner/background-header-2-desktop.jpg');
  display: flex;
  padding-top: 2em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
  background-size: cover;
  color: white;

  @media (max-width: 768px) {
    background-image: url('/assets/banner/background-header-2.jpg');
    height: 85vh;
    padding-top: 6em;
    object-fit: cover;
  }
`;

export const Title = styled.h1`
  font-size: 3.5rem;
  line-height: 100%;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
`;

export const Subtitle = styled.p`
  font-size: 0.9rem;
  letter-spacing: 6px;
  padding: 0.4em 1em;
  margin-top: -70px;
  text-transform: uppercase;
  border-radius: 7.715px;
  background: linear-gradient(
    91deg,
    rgba(108, 35, 35, 0.72) -0.03%,
    rgba(199, 65, 65, 0.72) 22.96%,
    rgba(255, 35, 35, 0.72) 42.95%,
    rgba(209, 68, 68, 0.72) 99.91%
  );
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
`;

export const DateInfo = styled.p`
  color: #fff;
  text-align: center;

  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 122%; /* 15.86px */
  letter-spacing: 1.18px;
`;

export const NarrationInfo = styled.p`
  padding-top: 1em;
  color: #fff;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 122%; /* 15.86px */
  letter-spacing: 1.18px;
`;

export const TitleBanner = styled.h1`
  font-size: 2.8em;
  line-height: 100%;
  font-weight: bold;
  margin: 0;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

export const SubtitleBanner = styled.p`
  font-size: 0.9rem;
  letter-spacing: 6px;
  padding: 0.4em 1em;

  text-transform: uppercase;
  border-radius: 7.715px;
  background: linear-gradient(
    91deg,
    rgba(108, 35, 35, 0.72) -0.03%,
    rgba(199, 65, 65, 0.72) 22.96%,
    rgba(255, 35, 35, 0.72) 42.95%,
    rgba(209, 68, 68, 0.72) 99.91%
  );
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
`;

export const ButtonWrapper = styled.div`
  margin-top: 2em;
`;

export const ButtonBanner = styled.button`
  padding: 1em 2em;
  background-color: #c74343;
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #ff2323;
  }
`;
