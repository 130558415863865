import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { routes } from './routes';

import supabase from './utils/supabase'; // Import here

function AppContent() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await supabase.from('leads').select('*');
      setData(data);
    };

    fetchData();
  }, []);

  return (
    <>
      <Routes>
        {routes.map((route, index) => {
          const Template = route.template;
          const Page = route.page;
          const title = route.title;

          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Template isPublic={route.isPublic}>
                  <Helmet title={`M O Y S A H- ${title}`} />
                  <Page />
                </Template>
              }
            />
          );
        })}
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
