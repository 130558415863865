import BlankTemplate from '../templates/Blank';

import Coming from '../pages/coming';
import homepage from '../pages/homepage';

export const routes = [
  // BUYING FLUX

  {
    path: '/subscribe',
    page: homepage,
    title: 'Direção audiovisual na Música',
    isPublic: true,
    template: BlankTemplate,
  },

  {
    path: '/',
    page: Coming,
    title: '(Pré Reserva) Direção audiovisual na Música',
    isPublic: true,
    template: BlankTemplate,
  },
];
