import styled from 'styled-components';
export const Wrapper = styled.div`
  color: #fff;
  font-family: 'Arial', sans-serif;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 2em;
    flex-direction: column;
  }
`;

export const SignatureImage = styled.img`
  position: absolute;
  bottom: -40;
  right: 1.5em;
  width: 20%;
  margin: auto;
  mix-blend-mode: exclusion;
  height: auto;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const WrapperContent = styled.div`
  background-color: #000;
  color: #fff;
  font-family: 'Arial', sans-serif;
  padding: 0 0 1em;
  text-align: center;
  width: 100%;
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0px;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 0em 4em 0 2em;
  }
`;
export const Title = styled.h1`
  font-size: 27px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 16px;
  text-transform: uppercase;
  text-align: left;
  width: 100%;

  @media (max-width: 768px) {
    text-align: left;
    font-size: 17px;
    line-height: 21px;
  }
`;

export const ArtistDropdown = styled.select`
  background-color: #333;
  color: #ffffff80;
  width: fit-content;
  height: 40px;
  border-radius: 40px;
  text-align: center;
  border: none;
  font-size: 14px;
  cursor: pointer;
  margin: 1em;
  position: relative;
  transition: background-color 0.3s;

  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover {
    background-color: #555;
  }
`;
export const VideoList = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-left: 0em;
  padding-right: 0em;
  gap: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    padding-left: 2em;
    padding-left: 2em;
  }
`;
export const VideoItem = styled.div`
  flex: none;
  width: 100%;
  max-width: 45%;
  min-height: 400px;
  background-size: cover;
  background-position: center;
  border-radius: 18px;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 768px) {
    max-width: 90%;
    height: 200px;
    min-height: 200px;
  }
`;
export const PlayButton = styled.button`
  position: absolute;
  bottom: 8%;
  right: 3%;
  font-size: 2.8em;
  color: rgb(255 255 255 / 54%);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const VideoTitle = styled.h2`
  font-size: 1.4rem;
  margin: 0;
  padding: 5px 20px 0;
  text-align: left;
`;

export const VideoSubtitle = styled.span`
  font-size: 0.6rem;
  text-align: left;
  word-wrap: break-word;
  max-width: 80%;
  padding: 5px 20px 20px;
`;

export const DropdownContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  padding: 1em 0;
  color: #fff;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 18px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  position: absolute;
  width: 100%;
  top: 2.8em;
  left: 0;
  z-index: 2;

  @media (max-width: 768px) {
  }
`;
export const DropdownItem = styled.div`
  background-color: transparent;
  width: 95%;
  border-radius: 15px;
  margin: auto;
  color: #fff;
  padding: 1em;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const DropdownWrapper = styled.div`
  background-color: #030303;
  border: 1px solid #4f4f4f;
  text-transform: uppercase;
  color: #ffffff80;
  width: 30%;
  padding: 0.8em 1.5em;
  border-radius: 30px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  font-family: 'Unbounded', sans-serif;
  position: relative;

  &:hover {
    background-color: #4f4f4f;
    transition: all 0.3 ease;
  }

  @media (max-width: 768px) {
    width: 60%;
    margin-top: 0.4em;

    svg {
      font-size: 1.5em;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin-top: 2em;
  }
`;
