import React from 'react';
import SubscribeBanner from '../../components/PreSave/SubscribeBanner';
import * as S from './styles';

const Coming = () => {
  return (
    <>
      <S.RowSpaced>
        <SubscribeBanner id='subscribeBanner' />
      </S.RowSpaced>
    </>
  );
};

export default Coming;
