import { useEffect, useState } from 'react';
import { BsMenuButtonWide } from 'react-icons/bs';
import { IoClose, IoVideocamOutline } from 'react-icons/io5';
import { MdOutlineQueryStats } from 'react-icons/md';
import { RiUserFollowLine, RiUserStarLine } from 'react-icons/ri';
import { TbShieldQuestion } from 'react-icons/tb';
import {
  TfiCommentsSmiley,
  TfiLayoutMediaLeftAlt,
  TfiVideoClapper,
} from 'react-icons/tfi';

import * as S from './styles';

export default function ModalMenu({ onClose }) {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 400);
  };
  const handleMenuItemClick = (sectionId) => {
    console.log(`Scrolling to ${sectionId}`);
    const element = document.getElementById(sectionId);

    if (element) {
      console.log(`Found ${sectionId}`, element);
      element.scrollIntoView({ behavior: 'smooth' });
      handleClose();
    } else {
      console.error('Element not found for ID:', sectionId);
    }
  };

  return (
    <S.ModalOverlay
      style={{
        opacity: isVisible ? 1 : 0,
        pointerEvents: isVisible ? 'auto' : 'none',
      }}
      onClick={handleClose}
    >
      <S.ModalContent
        style={{ transform: isVisible ? 'translateX(0)' : 'translateX(100%)' }}
        onClick={(e) => e.stopPropagation()}
      >
        <S.MenuListing>
          <S.ItemMenu onClick={() => handleMenuItemClick('modulesSection')}>
            Módulos <BsMenuButtonWide />
          </S.ItemMenu>
          <S.ItemMenu onClick={() => handleMenuItemClick('skillsSection')}>
            SKILLS
            <MdOutlineQueryStats />
          </S.ItemMenu>

          <S.ItemMenu onClick={() => handleMenuItemClick('projectsSection')}>
            realizações
            <IoVideocamOutline />
          </S.ItemMenu>
          <S.ItemMenu onClick={() => handleMenuItemClick('commentsSection')}>
            Comentários
            <TfiCommentsSmiley />
          </S.ItemMenu>
          <S.ItemMenu onClick={() => handleMenuItemClick('aboutSection')}>
            SOBRE MIM
            <RiUserStarLine />
          </S.ItemMenu>

          <S.ItemMenu onClick={() => handleMenuItemClick('subscribeSection')}>
            Inscreva-se
            <RiUserFollowLine />
          </S.ItemMenu>
          <S.ItemMenu onClick={() => handleMenuItemClick('audioVisualSection')}>
            AUDIOVISUAL
            <TfiVideoClapper />
          </S.ItemMenu>
          <S.ItemMenu onClick={() => handleMenuItemClick('mediaSection')}>
            na Mídia
            <TfiLayoutMediaLeftAlt />
          </S.ItemMenu>
          <S.ItemMenu onClick={() => handleMenuItemClick('faqSection')}>
            dúvidas
            <TbShieldQuestion />
          </S.ItemMenu>
        </S.MenuListing>
        <S.CloseButton onClick={handleClose}>
          <IoClose />
        </S.CloseButton>
      </S.ModalContent>
    </S.ModalOverlay>
  );
}
