import { createClient } from '@supabase/supabase-js';
import React, { useState } from 'react';
import { AiOutlineUser, AiOutlineWhatsApp } from 'react-icons/ai';
import { CiBookmarkCheck } from 'react-icons/ci';
import { FaPix } from 'react-icons/fa6';

import { IoCopyOutline } from 'react-icons/io5';

import { useEffect } from 'react';
import { LuSmartphone } from 'react-icons/lu';
import { MdOutlineMailOutline, MdPayment } from 'react-icons/md';
import { RiSecurePaymentLine } from 'react-icons/ri';
import InputMask from 'react-input-mask';

import * as S from './styles';

const supabase = createClient(
  'https://nmvbnvfodqcgqsnffidw.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5tdmJudmZvZHFjZ3FzbmZmaWR3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTE5ODU4NzMsImV4cCI6MjAyNzU2MTg3M30.xp2ER7qmnedJjsI2B78VsjdBgoPep85PW91qOM7AvuA',
);
const Modal = () => {
  const [isActive, setIsActive] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    setIsActive(true); // Ativa a animação de entrada quando o modal é montado
  }, []);

  const handleClose = (e) => {
    if (e.target === e.currentTarget) {
      setIsExiting(true); // Inicia a animação de saída
      setTimeout(() => onClose(), 500); // Atraso para coincidir com a animação
    }
  };

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [error, setError] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [qrCode, setQrCode] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Check if email exists
      let { data: emailExists } = await supabase
        .from('leads')
        .select('email')
        .eq('email', email);
      if (emailExists.length > 0) {
        setError('E-mail já cadastrado.');
        return;
      }

      // Check if telefone exists
      let { data: telefoneExists } = await supabase
        .from('leads')
        .select('telefone')
        .eq('telefone', telefone);
      if (telefoneExists.length > 0) {
        setError('Telefone já cadastrado.');
        return;
      }

      // Check if cpf exists
      let { data: cpfExists } = await supabase
        .from('leads')
        .select('cpf')
        .eq('cpf', cpf);
      if (cpfExists.length > 0) {
        setError('CPF já cadastrado.');
        return;
      }

      const leadData = {
        nome,
        telefone,
        email,
        cpf,
        metodo_pagamento: paymentMethod,
      };
      let { error } = await supabase.from('leads').insert([leadData]);
      if (error) throw error;

      if (paymentMethod === 'PIX') {
        setQrCode('/assets/payment/pix.jpg');
        setStep(3);
      } else if (paymentMethod === 'CREDIT_CARD') {
        setStep(2);
      }
    } catch (err) {
      setError('Erro ao salvar seus dados. Tente novamente.');
      console.error('Error submitting lead data:', err.message);
    }
  };

  const [valorPix, setValorPix] = useState('R$3.997,00');

  const copiarValorPix = () => {
    navigator.clipboard
      .writeText(valorPix.replace('R$', '').trim())
      .then(() => {
        alert('Valor copiado com sucesso!');
      })
      .catch((err) => {
        console.error('Falha ao copiar valor:', err);
      });
  };
  const handleWhatsAppRedirect = () => {
    const whatsappUrl =
      'https://api.whatsapp.com/send?phone=5511959700007&text=Olá, gostaria de mais informações.';
    window.open(whatsappUrl, '_blank');
  };

  const handleCreditCardSelection = (option) => {
    const url =
      option === 'NUBANK'
        ? 'https://checkout.nubank.com.br/WRmDV4NhrP9r63d'
        : 'https://pag.ae/7-p35jUxQ';
    window.open(url, '_blank');
    setStep(4);
  };

  // Handlers for input changes
  const handleNomeChange = (e) => setNome(e.target.value);
  const handleTelefoneChange = (e) =>
    setTelefone(e.target.value.replace(/\D/g, '')); // Remove caracteres não numéricos
  const handleCpfChange = (e) => setCpf(e.target.value.replace(/\D/g, '')); // Remove caracteres não numéricos
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePaymentMethodChange = (e) => setPaymentMethod(e.target.value);

  const [step, setStep] = useState(1); // 1: Entrada de dados, 2: Confirmação de pagamento, 3: QR Code / Redirecionamento

  return (
    <S.Form onSubmit={handleSubmit}>
      {step === 1 && (
        <>
          <S.InputWrapper>
            <S.IconWrapper>
              <AiOutlineUser />
            </S.IconWrapper>
            <S.Input
              type='text'
              placeholder='Nome'
              value={nome}
              onChange={handleNomeChange}
              required
            />
          </S.InputWrapper>

          <S.InputWrapper>
            <S.IconWrapper>
              <LuSmartphone />
            </S.IconWrapper>
            <InputMask
              mask='(99) 99999-9999'
              value={telefone}
              onChange={handleTelefoneChange}
              placeholder='Telefone'
              required
            >
              {(inputProps) => <S.Input {...inputProps} type='tel' />}
            </InputMask>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.IconWrapper>
              <MdOutlineMailOutline />
            </S.IconWrapper>
            <S.Input
              type='email'
              placeholder='E-mail'
              value={email}
              onChange={handleEmailChange}
              required
            />
          </S.InputWrapper>

          <S.InputWrapper>
            <S.IconWrapper>
              <MdOutlineMailOutline />
            </S.IconWrapper>
            <InputMask
              mask='999.999.999-99'
              value={cpf}
              onChange={handleCpfChange}
              placeholder='CPF'
              required
            >
              {(inputProps) => <S.Input {...inputProps} />}
            </InputMask>
          </S.InputWrapper>

          <S.OptionWrapper>
            <S.LabelOption isSelected={paymentMethod === 'PIX'}>
              <S.Option
                type='radio'
                name='paymentMethod'
                value='PIX'
                checked={paymentMethod === 'PIX'}
                onChange={handlePaymentMethodChange}
              />
              PIX
              <FaPix />
            </S.LabelOption>

            <S.LabelOption isSelected={paymentMethod === 'CREDIT_CARD'}>
              <S.Option
                type='radio'
                name='paymentMethod'
                value='CREDIT_CARD'
                checked={paymentMethod === 'CREDIT_CARD'}
                onChange={handlePaymentMethodChange}
              />
              Cartão de Crédito <MdPayment />
            </S.LabelOption>
          </S.OptionWrapper>

          {/* Submit button */}
          <S.SendButton type='submit'>
            Realizar pagamento <RiSecurePaymentLine />
          </S.SendButton>
        </>
      )}
      {step === 2 && paymentMethod === 'CREDIT_CARD' && (
        <S.PaymentOptions>
          <S.Heading>Escolha o seu banco preferido</S.Heading>
          <S.PaymentOptionButton
            brand='NUBANK'
            onClick={() => handleCreditCardSelection('NUBANK')}
          >
            <S.BankImage src='/assets/payment/nubank.png' alt='Nubank' />
          </S.PaymentOptionButton>
          <S.PaymentOptionButton
            brand='PAGSEGURO'
            onClick={() => handleCreditCardSelection('PAGSEGURO')}
          >
            <S.BankImage src='/assets/payment/pagseguro.png' alt='PagSeguro' />
          </S.PaymentOptionButton>
        </S.PaymentOptions>
      )}
      {step === 3 && paymentMethod === 'PIX' && (
        <>
          <S.QRCodeWarpper>
            <S.QRCodeImage src={qrCode} alt='QR Code para pagamento via PIX' />
            <span>33.589.067/0001-62</span>
            Abra seu banco e leia o QR code, insira o valor abaixo e confirme o
            pagamento para que a inscrição prossiga.<br></br>
            {valorPix}
            <S.CopyToClipboardButton onClick={copiarValorPix}>
              <IoCopyOutline />
              Copiar valor
            </S.CopyToClipboardButton>
            <S.ConfirmButton onClick={() => setStep(4)}>
              Já paguei
            </S.ConfirmButton>
          </S.QRCodeWarpper>
        </>
      )}

      {step === 6 && paymentMethod === 'PIX' && (
        <>
          <S.QRCodeWarpper>
            <CiBookmarkCheck />
            Obrigado por dar continuidade, vamos confirmar o pagamento e
            entramos em contato.
            <S.ConfirmButtonGreen onClick={handleWhatsAppRedirect}>
              <AiOutlineWhatsApp />
              WhatsApp
            </S.ConfirmButtonGreen>
          </S.QRCodeWarpper>
        </>
      )}
      {step === 4 && (
        <S.RedirectConfirmation>
          Você foi redirecionado ao banco desejado. Caso já realizou o
          pagamento.
          <S.ConfirmButton onClick={() => setStep(5)}>
            Clique aqui
          </S.ConfirmButton>
        </S.RedirectConfirmation>
      )}
      {step === 5 && (
        <S.QRCodeWarpper>
          <CiBookmarkCheck />
          Obrigado por dar continuidade, vamos confirmar o pagamento e entramos
          em contato.
          <S.ConfirmButtonGreen onClick={handleWhatsAppRedirect}>
            <AiOutlineWhatsApp />
            WhatsApp
          </S.ConfirmButtonGreen>
        </S.QRCodeWarpper>
      )}
      {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
    </S.Form>
  );
};

export default Modal;
