import React from 'react';
import * as S from './styles';

const MasterclassBanner = ({ id }) => {
  return (
    <S.Wrapper id={id}>
      {' '}
      <S.NarrationInfo>Narrativa audiovisual com Moysah</S.NarrationInfo>
      <S.TitleBanner>
        Direção<br></br>Audiovisual<br></br>na Música
      </S.TitleBanner>
      <S.SubtitleBanner>Masterclass</S.SubtitleBanner>
    </S.Wrapper>
  );
};

export default MasterclassBanner;
