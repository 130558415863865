import React from 'react';
import { useInView } from 'react-intersection-observer';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import * as S from './styles';
const commentsData = [
  {
    username: '@arthursabara',
    comment: 'moysah é monstro no áudio visual, referência',
  },

  {
    username: '@gustavobatista8484',
    comment:
      'moysah você é monstro. todo o time que participou ta de parabéns!!!',
  },
  {
    username: '@gaxies9599',
    comment: 'como assim fim mano? não pode acabar isso não',
  },
  {
    username: '@gustavoborgxs2071',
    comment: '6 minutos de trap, isso é novidade kk',
  },
  {
    username: '@deysonlucas3343',
    comment: 'producao de clipe nivel travis scott',
  },
  {
    username: '@natane3654',
    comment: 'caralhooo tá lindo esse clipe q issooooo',
  },
  {
    username: '@rafaell_leao',
    comment:
      'mais uma produção braba sempre um clipe melhor que o outro, muita evolução!!!',
  },

  // ... add more comments as needed
];
const CommentCard = ({ username, comment }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // Classe 'in-view' será adicionada ao cartão individualmente
  return (
    <S.CommentCard ref={ref} className={inView ? 'in-view' : ''}>
      <S.Username>{username}</S.Username>
      <S.CommentText>{comment}</S.CommentText>
    </S.CommentCard>
  );
};

const Comments = ({ id }) => {
  return (
    <S.CommentsWrapper id={id}>
      <S.Content>
        <S.Title>Comentários</S.Title>
        <S.CommentsGrid>
          {commentsData.map((comment, index) => (
            <CommentCard
              key={index}
              username={comment.username}
              comment={comment.comment}
            />
          ))}
        </S.CommentsGrid>
      </S.Content>
    </S.CommentsWrapper>
  );
};

export default Comments;
