import React, { useState } from 'react';
import { AiOutlineTeam } from 'react-icons/ai';
import { GiMusicSpell } from 'react-icons/gi';
import { MdSwitchAccessShortcutAdd } from 'react-icons/md';
import { RiEyeLine, RiMovie2Line } from 'react-icons/ri';
import { SiReactrouter } from 'react-icons/si';
import VisibilitySensor from 'react-visibility-sensor';
import * as S from './styles';

const skillsData = [
  { icon: <RiEyeLine />, label: 'Visão de mercado', progress: 70 },
  {
    icon: <GiMusicSpell />,
    label: 'Relação música e imagem',
    progress: 80,
  },
  { icon: <RiMovie2Line />, label: 'Storytelling', progress: 60 },
  { icon: <SiReactrouter />, label: 'Roteiro', progress: 75 },
  { icon: <AiOutlineTeam />, label: 'Equipe e equipamentos', progress: 90 },
  { icon: <MdSwitchAccessShortcutAdd />, label: 'Montagem', progress: 50 },
  // ... add more skills as needed
];
const SkillsComponent = ({ id }) => {
  const [skillsVisible, setSkillsVisible] = useState(
    skillsData.map(() => false),
  );

  const handleVisibilityChange = (isVisible, index) => {
    if (isVisible && !skillsVisible[index]) {
      setSkillsVisible((currentVisible) =>
        currentVisible.map((visible, i) => (i === index ? true : visible)),
      );
    }
  };
  return (
    <S.SkillsWrapper id={id}>
      <S.Title>MELHORE SEUS SKILLS</S.Title>
      <S.SubHeader>o impacto da experiência</S.SubHeader>

      {skillsData.map((skill, index) => (
        <VisibilitySensor
          key={index}
          partialVisibility
          onChange={(isVisible) => handleVisibilityChange(isVisible, index)}
          offset={{ bottom: 200 }}
        >
          <S.Content>
            <S.SkillItem>
              <S.ProgressBase />
              <S.IconWrapper
                style={{
                  width: `${skillsVisible[index] ? skill.progress : 0}%`,
                }}
              >
                {skill.icon}
                <span style={{ opacity: skillsVisible[index] ? 1 : 0 }}>
                  {skill.label}
                </span>
              </S.IconWrapper>
              <S.ProgressBar
                style={{
                  width: `${skillsVisible[index] ? skill.progress : 0}%`,
                }}
              />
            </S.SkillItem>
          </S.Content>
        </VisibilitySensor>
      ))}
    </S.SkillsWrapper>
  );
};

export default SkillsComponent;
