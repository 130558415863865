import React, { useEffect, useRef, useState } from 'react';
import { BsPlayCircleFill } from 'react-icons/bs';
import { HiChevronDown } from 'react-icons/hi2';

import YouTube from 'react-youtube';
import * as S from './styles';

const videoData = [
  {
    title: 'KIVITZ',
    subtitle: 'HomeOffice (feat. Wesley Camilo)',
    imageUrl: '/assets/videos/homeoffice.png',
    videoUrl: 'https://www.youtube.com/embed/Vv9HEvf_kUE?si=xgzI7RXEDGu272g2',
  },
  {
    title: 'KIVITZ',
    subtitle: 'Óbvio - Lugar | Clipe Oficial (Stella Yeshua, Silvera, Kivitz)',
    imageUrl: '/assets/videos/obvio-lugar.png',
    videoUrl: 'https://www.youtube.com/embed/0ZtM13X642k?si=Rbd2CG0DO9czuYZo',
  },
  {
    title: 'KIVITZ',
    subtitle:
      'Óbvio - Agora é | Clipe Oficial (Stella Yeshua, Silvera, Kivitz)',
    imageUrl: '/assets/videos/obvio-agora.png',
    videoUrl: 'https://www.youtube.com/embed/toK5kztqSvg?si=oCDwbBkIizhW2Sqc',
  },

  {
    title: 'RINCON SAPIENCIA',
    subtitle: 'Rincon Sapiência - Cotidiano',
    imageUrl: '/assets/videos/cotidiano.png',
    videoUrl: 'https://www.youtube.com/embed/-gGhZ0TvLJU?si=O1lDc8mNDirR7uer',
  },

  {
    title: 'RINCON SAPIENCIA',
    subtitle: 'Rincon Sapiência - Xona',
    imageUrl: '/assets/videos/xona.png',
    videoUrl: 'https://www.youtube.com/embed/dTo_czVQWEo?si=0zMrNNk5uahQz9Mg',
  },

  {
    title: 'SINTESE',
    subtitle: 'Síntese - Lei dos Céus (Videoclipe Oficial)',
    imageUrl: '/assets/videos/sintese.png',
    videoUrl: 'https://www.youtube.com/embed/L1sja1D40ak?si=G3xy_7FgCj8WZ0OG',
  },

  {
    title: 'SINTESE',
    subtitle: 'Síntese - A Rua Sabe (Videoclipe Oficial)',
    imageUrl: '/assets/videos/rua-sabe.png',
    videoUrl: 'https://www.youtube.com/embed/-a1ygSJvb-o?si=_Q1CCcC0js207ViP',
  },

  {
    title: 'EMICIDA',
    subtitle: 'Emicida - Baiana (Videoclipe) ft. Caetano Veloso',
    imageUrl: '/assets/videos/baiana.png',
    videoUrl: 'https://www.youtube.com/embed/3QEFDraI0XQ?si=U2cH2eQDkNajhX1T',
  },

  {
    title: 'EMICIDA',
    subtitle: 'Emicida - Madagascar',
    imageUrl: '/assets/videos/madagascar.png',
    videoUrl: 'https://www.youtube.com/embed/q0sJZZd4uPU?si=DAnABBi_TJYKjEOx',
  },

  {
    title: 'RACIONAIS',
    subtitle:
      'Racionais MCs - Capítulo 4 Versículo 3 (Racionais 3 Décadas Ao Vivo)',
    imageUrl: '/assets/videos/capitulo4.png',
    videoUrl: 'https://www.youtube.com/embed/Cyz8ydKBCYw?si=KRu0dL2JIZ7gxn3n',
  },

  {
    title: 'JÉ SANTIAGO',
    subtitle:
      'Jé x Igu - SNAKES/RUSH (Videoclipe Oficial) || HORA DO RUSH Vol. 3',
    imageUrl: '/assets/videos/sneakes.png',
    videoUrl: 'https://www.youtube.com/embed/a9qhTQDpkd8?si=nK--Dchxnn_Gzrcg',
  },

  {
    title: 'JÉ SANTIAGO',
    subtitle:
      'Jé x Igu - MERCEDES/HONDA (Videoclipe Oficial) || HORA DO RUSH Vol. 1',
    imageUrl: '/assets/videos/mercedez-honda.png',
    videoUrl: 'https://www.youtube.com/embed/nU5BSpc4vgQ?si=zagBe68t4_e69lD7',
  },

  {
    title: 'JÉ SANTIAGO',
    subtitle:
      'Jé x Igu - BALENCIAGA/BISKAT (Videoclipe Oficial) || Hora do Rush, Vol. 2',
    imageUrl: '/assets/videos/balanciaga-biskat.png',
    videoUrl: 'https://www.youtube.com/embed/UeN6dYMVB_U?si=pp9FRV70QAsEZGbX',
  },

  {
    title: 'YUNK VINO',
    subtitle: 'Yunk Vino x Mc Igu - 190 (Clipe Oficial)',
    imageUrl: '/assets/videos/190yunk.png',
    videoUrl: 'https://www.youtube.com/embed/9PecYxmyMEc?si=3ifrLmdr6k8Sc2cx',
  },

  {
    title: 'MC IGU',
    subtitle: 'Mc Igu x Yunk Vino - Margiela (Clipe Oficial) ',
    imageUrl: '/assets/videos/margiela.png',
    videoUrl: 'https://www.youtube.com/embed/HO2exvdj7DQ?si=IhVsefMcc_IQAS9r',
  },

  {
    title: 'DUQUESA',
    subtitle: 'Duquesa feat. Yunk Vino - GEMINI (Clipe Oficial)',
    imageUrl: '/assets/videos/gemini.png',
    videoUrl: 'https://www.youtube.com/embed/L8B9_R1eCz8?si=S-oFYbjUmlDvZItP',
  },

  {
    title: 'RASHID',
    subtitle: 'Rashid - "Sem Sorte" (CLIPE OFICIAL)',
    imageUrl: '/assets/videos/semsorte.png',
    videoUrl: 'https://www.youtube.com/embed/Ubmxmbr4KZ0?si=EZcN2uowiimVvO9t',
  },

  {
    title: 'RASHID',
    subtitle: 'Rashid - "Estereótipo" (VÍDEO OFICIAL)',
    imageUrl: '/assets/videos/estereotipo.png',
    videoUrl: 'https://www.youtube.com/embed/uJHyhG6sXe0?si=75iipVHDx-Gip3Rk',
  },

  {
    title: 'CORUJA BC1',
    subtitle: 'Coruja BC1 - NDDN',
    imageUrl: '/assets/videos/nddn.png',
    videoUrl: 'https://www.youtube.com/embed/QJ6SWvdHWzA?si=1IfFDZQHzsE5rmwk',
  },

  {
    title: 'DOCUMENTAL',
    subtitle: 'Rashid - Tão Real, a série | Temporada 1 | Episódio 1',
    imageUrl: '/assets/videos/taoreal.png',
    videoUrl: 'https://www.youtube.com/embed/u09J8sLrEuc?si=cFW1kYjiUaBjrctp',
  },

  {
    title: 'DOCUMENTAL',
    subtitle: 'Rashid - Tão Real, a série | Temporada 1 | Episódio 2',
    imageUrl: '/assets/videos/taoreal2.png',
    videoUrl: 'https://www.youtube.com/embed/u09J8sLrEuc?si=6bdMBdjRXc3k7wf5',
  },

  {
    title: 'DOCUMENTAL',
    subtitle: 'RACIONAIS 3 DÉCADAS - Quanto Vale o Show? (Brasília)',
    imageUrl: '/assets/videos/racionais-3-decadas.png',
    videoUrl: 'https://www.youtube.com/embed/aIApYA298dg?si=iZHGuMYT9i-tifv7',
  },

  {
    title: 'DOCUMENTAL',
    subtitle: 'Emicida no Festival Jazz no Kubiko em Luanda/Angola (2017)',
    imageUrl: '/assets/videos/festival-emicida.png',
    videoUrl: 'https://www.youtube.com/embed/Nf59bEZz9NQ?si=623HPHNK7qFZJU4Q',
  },

  {
    title: 'DOCUMENTAL',
    subtitle:
      'Lab Fantasma - Coleção Avuá 2017 - Making Of do desfile na #SPFWN44',
    imageUrl: '/assets/videos/lab-fantasama.png',
    videoUrl: 'https://www.youtube.com/embed/E16CnGEb-f0?si=nSHX8T8awBoB2-_T',
  },

  {
    title: 'MANO BROWN',
    subtitle: 'Mano Brown - O chefe',
    imageUrl: '/assets/videos/chefe.png',
    videoUrl:
      'https://app.frame.io/presentations/99616d84-e445-4961-be04-32b87f9149e6',
  },
];

const artistData = [
  'KIVITZ',
  'RACIONAIS',
  'JÉ SANTIAGO',
  'MANO BROWN',
  'RINCON SAPIENCIA',
  'SINTESE',
  'EMICIDA',
  'YUNK VINO',
  'MC IGU',
  'DUQUESA',
  'RASHID',
  'CORUJA BC1',
  'OLIMPICO',
];

const VideoGalleryComponent = ({ id }) => {
  const [activeVideoId, setActiveVideoId] = useState('');
  const [selectedArtist, setSelectedArtist] = useState(''); // Default artist set
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const extractVideoId = (url) => {
    const urlObj = new URL(url);
    const videoId = urlObj.searchParams.get('v');
    return videoId || urlObj.pathname.split('/').pop();
  };

  const handleVideoClick = (videoUrl) => {
    const videoId = extractVideoId(videoUrl);
    setActiveVideoId(videoId);
  };

  const handleArtistChange = (artist) => {
    setSelectedArtist(artist);
    setIsDropdownVisible(false);
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const filteredVideoData = videoData.filter(
    (video) => selectedArtist === '' || video.title === selectedArtist,
  );

  return (
    <>
      <S.Row id={id}>
        <S.Wrapper>
          <S.TitleContent>
            <S.Title>Projetos que assinei no mundo da música</S.Title>
            <S.SignatureImage src='/assets/videos/logo.svg' alt='Assinatura' />
          </S.TitleContent>
          <S.DropdownWrapper onClick={toggleDropdown}>
            Filtre por artista <HiChevronDown />
            {isDropdownVisible && (
              <S.DropdownContainer
                ref={dropdownRef}
                isVisible={isDropdownVisible}
              >
                {artistData.map((artist, index) => (
                  <S.DropdownItem
                    key={index}
                    onClick={() => handleArtistChange(artist)}
                  >
                    {artist}
                  </S.DropdownItem>
                ))}
                <S.DropdownItem onClick={() => handleArtistChange('')}>
                  Ver todos
                </S.DropdownItem>
              </S.DropdownContainer>
            )}
          </S.DropdownWrapper>
        </S.Wrapper>

        <S.WrapperContent>
          <S.VideoList>
            {filteredVideoData.map((video, index) => (
              <S.VideoItem
                key={index}
                style={{ backgroundImage: `url(${video.imageUrl})` }}
                onClick={() => handleVideoClick(video.videoUrl)}
              >
                {activeVideoId === extractVideoId(video.videoUrl) ? (
                  <YouTube
                    videoId={activeVideoId}
                    opts={{ height: '340px', width: '100%' }}
                  />
                ) : (
                  <>
                    <S.VideoTitle>{video.title}</S.VideoTitle>
                    <S.VideoSubtitle>{video.subtitle}</S.VideoSubtitle>
                    <S.PlayButton>
                      <BsPlayCircleFill />
                    </S.PlayButton>
                  </>
                )}
              </S.VideoItem>
            ))}
          </S.VideoList>
        </S.WrapperContent>
      </S.Row>
    </>
  );
};

export default VideoGalleryComponent;
