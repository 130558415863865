import React from 'react';
import { LuBuilding2 } from 'react-icons/lu';
import { PiAirplaneTakeoffLight } from 'react-icons/pi';
import { TbChairDirector } from 'react-icons/tb';
import * as S from './styles';

const About = ({ id }) => {
  return (
    <S.AboutWrapper id={id}>
      <S.Content>
        <TbChairDirector />A trajetória ado jovem diretor tem aproximadamente 6
        anos, estudou fotografia na Escola Panamericana de Artes em São Paulo e
        filmworks na AIC (Academia Internacional de Cinema).
        <S.Curious>
          Além de seu olhar fresco e cuidadoso na fotografia, a sua habilidade
          com as palavras é muito latente, seja nas campanhas publicitárias em
          que trabalha, onde não é raro ver sua voz, seja em inglês ou
          português, transformando o filme publicitário numa experiência
          sinestésica pra quem o assiste;
        </S.Curious>
      </S.Content>{' '}
      <S.Content>
        <LuBuilding2 />
        Nessa fase, o conceito da presença da Red Bull no Hip Hop estava tão
        sólido que logo Emicida o chamou pra encabeçar a produção audiovisual da
        LAB, onde foi responsável pela cobertura das turnês dos artistas da
        gravadora, vídeos dos grandes desfiles da marca no SPFW, das matérias e
        conteúdos das redes sociais do time LAB e de videoclipes marcantes do
        Emicida, como "Madagascar" e "Baiana".
      </S.Content>
      <S.Content>
        <PiAirplaneTakeoffLight />
        Nessa fase Moysah pode viajar trabalhando bastante, e não parou de
        estudar.  Os longos períodos em Los Angeles o fez desenvolver muito suas
        técnicas refinou muito o olhar e o resultado final de tudo que coloca a
        mão. Foram filmes para Vivara, Audi, Jaguar, MasterCard e Jeep que
        ampliaram os horizontes do que se pode fazer num trabalho audiovisual.
      </S.Content>
    </S.AboutWrapper>
  );
};

export default About;
