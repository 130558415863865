import React from 'react';
import About from '../../components/About';
import BuyBlock from '../../components/BuyBlock';
import Comments from '../../components/Comments';
import Faq from '../../components/Faq';
import Footer from '../../components/Global/Footer';
import Header from '../../components/Global/Header';
import MasterclassBanner from '../../components/MasterclassBanner';
import Modules from '../../components/Modules';
import OnMedia from '../../components/OnMedia';
import Skills from '../../components/Skills';
import TextPanelComponent from '../../components/TextPanelComponent';
import VideoGalleryComponent from '../../components/VideoGalleryComponent';
import * as S from './styles';

const Homepage = () => {
  return (
    <>
      <Header />
      <MasterclassBanner />
      <S.RowInline>
        <Modules id='modulesSection' />
        <Skills id='skillsSection' />
      </S.RowInline>
      <S.RowBlock>
        <VideoGalleryComponent id='projectsSection' />
        <Comments id='commentsSection' />
      </S.RowBlock>
      <S.Row>
        <About id='aboutSection' />
      </S.Row>
      <S.RowSpaced>
        <BuyBlock id='subscribeSection' />
        <TextPanelComponent id='audioVisualSection' />
      </S.RowSpaced>

      <S.Row>
        <OnMedia id='mediaSection' />
        <Faq id='faqSection' />
      </S.Row>

      <Footer />
    </>
  );
};

export default Homepage;
