import styled from 'styled-components';

export const TextPanel = styled.div`
  color: white;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  display: flex;
  margin: auto;
  width: 100%;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Heading = styled.h1`
  font-size: 25px; /* Adjust the size as needed */
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 21px;
    line-height: 21px;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  span {
    color: transparent;
    background-image: linear-gradient(45deg, #fa0c0c, #ff6347, #e3170a);
    background-size: 200% 200%;
    animation: gradient 5s ease infinite;
    -webkit-background-clip: text;
    background-clip: text;
    display: inline;
    font-family: 'Unbounded', sans-serif;
  }
`;

export const ContentBox = styled.div`
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  margin: auto;
`;

export const Content = styled.p`
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  text-align: left;
  opacity: 0.8;
`;

export const TopImage = styled.img`
  width: 50%;
  border-radius: 21px;
  height: 100%;
  z-index: 0;
  display: block;
  position: relative;

  @media (max-width: 768px) {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
`;
