import styled from 'styled-components';

export const AboutWrapper = styled.section`
  width: 100%;
  color: #fff;
  display: flex;
  gap: 1;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 1em;
  }
`;

export const TopImage = styled.img`
  width: 700px;
  height: auto;
  border-radius: 21px;

  z-index: 0;
  display: block;
  position: relative;

  @media (max-width: 768px) {
    height: 500px;
    object-fit: cover;
  }
`;

export const Signature = styled.img`
  position: absolute;
  top: 20px; // Adjust as necessary to overlay on the TopImage
  left: 20px; // Adjust as necessary
  height: 60px; // Adjust based on the actual size of the signature
  width: auto;
`;

export const Curious = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 1em;
  text-align: left;
  font-weight: 300;
  color: #ffffff90;
  border-radius: 10px;
  font-family: 'Helvetica', sans-serif;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const Content = styled.div`
  font-size: 16px;
  font-family: 'Helvetica', sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 1em;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
  margin: 0 auto;

  svg {
    font-size: 6cqi;
    margin-right: 10px;
    color: #fa0c0c;
  }

  @media (max-width: 768px) {
    padding: 2em 0;
    font-size: 14px;
    line-height: 18px;

    svg {
      font-size: 40px;
    }
  }
`;

export const Highlight = styled.span`
  background-color: ${({ color }) =>
    color || 'rgba(250, 204, 0, 0.5)'}; // Cor padrão ou cor personalizada
  font-weight: bold;
  padding: 0 4px; // Adiciona um pouco de padding ao redor do texto
  border-radius: 2px; // Suaviza as bordas do destaque
`;
