import styled from 'styled-components';

export const Container = styled.div``;

export const HeadSection = styled.div`
  background-color: #fa0c0c;
  padding: 1em;
  z-index: 1;
  border-radius: 0 0 31px 31px;
`;

export const RowBlock = styled.div`
  display: flex;
  max-width: 1500px;
  margin: auto;
  padding: 2em;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  margin: 0 auto;
  max-width: 1500px;

  @media (max-width: 768px) {
    padding: 2em 0;
  }
`;

export const RowSpaced = styled.div`
  display: flex;
  max-width: 1500px;
  margin: 4em auto 8em;
  padding: 2em;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2em;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2em;
    margin: 0em auto 4em;
  }
`;
export const Row = styled.div`
  display: flex;
  max-width: 1500px;
  margin: auto;
  padding: 2em;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3em;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2em;
  }
`;

export const RowInline = styled.div`
  display: flex;
  max-width: 1500px;
  margin: auto;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3em;
  padding: 2em;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2em 0;
    gap: 0;
  }
`;
