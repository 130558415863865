import styled from 'styled-components';

export const OnMediaWrapper = styled.div`
  background-color: #000; // Assuming a dark background as seen in the image
  color: #fff;

  border-radius: 20px; // Adjust based on your design
  font-family: 'Arial', sans-serif;
  width: 40%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-size: 27px;
  margin-bottom: 30px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 21px;
  }
`;

export const MediaFeature = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

export const MediaImage = styled.img`
  width: 100%;
  border-radius: 20px; // Keep consistent with MediaFeature
  display: block;
`;

export const SearchIconOverlay = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff80;
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  color: white;
  font-size: 25px; // Adjust size based on design
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  :hover {
    background-color: transparent;
    scale: 1.1;
  }
`;

export const MediaContent = styled.div`
  position: absolute;
  bottom: 20px;
  left: 10px;
  right: 10px;

  border-radius: 10px; // Slight rounding of corners
`;

export const MediaTitle = styled.h3`
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  padding: 0em 2em;

  text-align: center;

  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 18px;
  }
`;

export const MediaSource = styled.p`
  font-size: 12px;
  letter-spacing: 2px;
  margin: 0;
  margin-top: 1em;
  text-align: center;
`;
