import { useEffect, useRef, useState } from 'react';
import { CgMenuRight } from 'react-icons/cg';
import ModalMenu from './ModalMenu';
import * as S from './styles';

const logo = 'assets/header/logo-moysah.svg';

export default function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const lastScrollY = useRef(0);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window !== 'undefined') {
        const currentScrollY = window.scrollY;
        setShowHeader(
          currentScrollY < lastScrollY.current || currentScrollY <= 0,
        );
        setIsScrolled(currentScrollY > 50); // Adjust the value 50 based on when you want to change the background
        lastScrollY.current = currentScrollY;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <S.HeaderContainer show={showHeader} isScrolled={isScrolled}>
        <S.LogoImage src={logo} width={40} height={40} alt='Logo Sanches' />

        <S.MenuButton onClick={toggleMenu}>
          <CgMenuRight />
        </S.MenuButton>
      </S.HeaderContainer>
      {isMenuOpen && <ModalMenu onClose={toggleMenu} />}
    </>
  );
}
