import styled from 'styled-components';

export const CommentsWrapper = styled.div``;
export const Content = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 5em;
  border-radius: 28px 28px 0px 0px;
  width: 100%;
  padding: 0 2em;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Aspas = styled.h1`
  font-size: 40px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 16px;
  text-transform: uppercase;
  text-align: center;
  opacity: 0.2;
  color: #fff;
  height: fit-content;
`;

export const Title = styled.h2`
  color: #fff;
  font-size: 27px;
  display: flex;
  line-height: 30px;

  gap: 10px;
  align-items: flex-start;
  text-transform: uppercase;
  text-align: left;
  padding: 0 0em;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 21px;
    padding: 0 1em;
  }
`;
export const CommentBubble = styled.div`
  background-color: #fff;
  -webkit-mask-image: linear-gradient(to right, transparent 0%, black 75%);
  mask-image: linear-gradient(to right, transparent 0%, black 75%);
  border-radius: 15px;
  /* margin: 0 10px !important; */
  padding: 1.3em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  backdrop-filter: blur(5px);
  flex-direction: column;
  justify-content: space-between !important;
  gap: 10px;
  max-width: 300px;

  .slider {
    display: flex;
  }
`;

export const Username = styled.h3`
  color: #000; /* Dark text for the username */
  font-weight: bold;
  line-height: 0;
  font-size: 17px;
`;

export const CommentText = styled.p`
  color: #000;
  padding: 0;
  margin: 0px 0;
  margin-top: 5%;
  font-size: 15px;
`;
export const CommentsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  padding: 0em;
  margin-top: 0em;

  @media (max-width: 768px) {
    padding: 0em 1em;
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr); // Três cartões por linha no desktop
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(
      4,
      1fr
    ); // Quatro cartões por linha em telas maiores
  }
`;

export const CommentCard = styled.div`
  background-color: #fff;
  border-radius: 15px;
  padding: 1.3em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;

  &.in-view {
    opacity: 1;
    transform: translateY(0);
  }
`;
