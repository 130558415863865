import React from 'react';
import Subscribe from '../Subscribe';
import * as S from './styles';
const logo = 'assets/header/logo-moysah.svg';
const SubscribeBanner = ({ id }) => {
  return (
    <S.Wrapper id={id}>
      <S.LogoImage src={logo} width={100} height={100} alt='Logo Sanches' />
      {/* <S.NarrationInfo>Narrativa audiovisual com Moysah</S.NarrationInfo> */}
      <S.TitleBanner>
        Direção<br></br>Audiovisual<br></br>na Música
      </S.TitleBanner>
      <S.SubtitleBanner>Masterclass</S.SubtitleBanner>
      <Subscribe id='subscribeSection' />
    </S.Wrapper>
  );
};

export default SubscribeBanner;
