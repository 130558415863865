import React, { useState } from 'react';
import { GoPlusCircle } from 'react-icons/go';
import {
  IoBookOutline,
  IoChatboxEllipsesOutline,
  IoCheckmarkDoneOutline,
  IoConstructOutline,
  IoCutOutline,
  IoHammerOutline,
  IoMusicalNotesOutline,
  IoStarOutline,
  IoSyncOutline,
  IoVideocamOutline,
} from 'react-icons/io5';

import * as S from './styles';
const modulesData = [
  {
    title: 'MÓDULO 1',
    subtitle: 'AUDIOVISUAL E A MÚSICA #começando',
    icon: <IoMusicalNotesOutline />,
    about:
      'Neste módulo, você vai aprender sobre a importância do audiovisual e da música, e como eles se relacionam.',
  },
  {
    title: 'MÓDULO 2',
    subtitle: 'FUNDAMENTOS #fundamentos',
    icon: <IoBookOutline />,
    about:
      'Neste módulo, você vai aprender sobre os fundamentos do audiovisual, incluindo a história, a teoria e a prática.',
  },
  {
    title: 'MÓDULO 3',
    subtitle: 'STORYTELLING #produção',
    icon: <IoChatboxEllipsesOutline />,
    about:
      'Neste módulo, você vai aprender sobre storytelling, ou seja, como contar histórias através do audiovisual.',
  },
  {
    title: 'MÓDULO 4',
    subtitle: 'EQUIPAMENTOS e SOFTWARES #ferramentas',
    icon: <IoConstructOutline />,
    about:
      'Neste módulo, você vai aprender sobre os equipamentos e softwares necessários para produzir conteúdo audiovisual.',
  },
  {
    title: 'MÓDULO 5',
    subtitle: 'Técnicas de Gravação #gravação',
    icon: <IoVideocamOutline />,
    about:
      'Neste módulo, você vai aprender sobre as técnicas de gravação de áudio e vídeo.',
  },
  {
    title: 'MÓDULO 6',
    subtitle: 'Sincronização de Áudio e Vídeo #sincronização',
    icon: <IoSyncOutline />,
    about:
      'Neste módulo, você vai aprender sobre a sincronização de áudio e vídeo, e como fazer isso de forma eficiente.',
  },
  {
    title: 'MÓDULO 7',
    subtitle: 'Edição e Efeitos Visuais #edição',
    icon: <IoCutOutline />,
    about:
      'Neste módulo, você vai aprender sobre edição e efeitos visuais, e como aplicá-los ao seu conteúdo.',
  },
  {
    title: 'MÓDULO 8',
    subtitle: 'Pós-Produção e Finalização #pós-produção',
    icon: <IoCheckmarkDoneOutline />,
    about:
      'Neste módulo, você vai aprender sobre pós-produção e finalização, e como finalizar o seu conteúdo para publicação.',
  },
  {
    title: 'MÓDULO 9',
    subtitle: 'Projeto Prático #projetoprático',
    icon: <IoHammerOutline />,
    about:
      'Neste módulo, você vai aplicar tudo o que aprendeu em um projeto prático, para consolidar o seu conhecimento.',
  },
  {
    title: 'MÓDULO 10',
    subtitle: 'Revisão, Feedback e Encerramento #conclusão',
    icon: <IoStarOutline />,
    about:
      'Neste módulo, você vai revisar tudo o que aprendeu, receber feedback e encerrar o curso com chave de ouro.',
  },
];
const Modules = ({ id }) => {
  const [expandedModuleIndex, setExpandedModuleIndex] = useState(null);

  const handleModuleClick = (index) => {
    setExpandedModuleIndex(expandedModuleIndex === index ? null : index); // Alternar expansão
  };

  return (
    <S.ModulesWrapper id={id}>
      <S.Header>O que você vai aprender?</S.Header>
      <S.SubHeader>Veja todos os módulos</S.SubHeader>
      <S.Row>
        {modulesData.map((module, index) => (
          <React.Fragment key={index}>
            <S.ModuleItem
              onClick={() => handleModuleClick(index)}
              isActive={expandedModuleIndex === index}
            >
              <S.ModuleTitle>
                <span>
                  {' '}
                  {module.icon}
                  {module.title}
                </span>{' '}
                {module.subtitle}
              </S.ModuleTitle>

              {/* <S.ModuleContent isActive={expandedModuleIndex === index}>
                {module.subtitle}
              </S.ModuleContent> */}
              {expandedModuleIndex === index && (
                <S.Description isVisible={true}>{module.about}</S.Description>
              )}

              <S.MoreIcon>
                <GoPlusCircle />
              </S.MoreIcon>
            </S.ModuleItem>
          </React.Fragment>
        ))}
      </S.Row>
    </S.ModulesWrapper>
  );
};

export default Modules;
