import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import Modal from './Modal'; // Certifique-se de que o caminho esteja correto
import * as S from './styles';

const BuyBlock = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);
  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      onClose(); // Chame a função de fechamento passada nas props
    }, 300); // Este valor deve corresponder à duração da animação de saída
  };

  const handleBackdropClick = (e) => {
    if (e.currentTarget === e.target) {
      // Certifique-se de que o clique vem do backdrop
      handleClose();
    }
  };

  return (
    <S.Content>
      <S.BuyWrapper id={id}>
        <S.Title>GARANTA AGORA SEU MASTERPASS</S.Title>
        <S.PriceWrapper>
          <S.NewPriceWrapper>
            <S.OldPrice>De R$ 5.997,00</S.OldPrice>
            <S.NewPriceLabel>POR 12x DE</S.NewPriceLabel>
          </S.NewPriceWrapper>

          <S.NewPrice>
            <span> R$</span>374,16
          </S.NewPrice>
          <S.FullPrice>OU R$ 4.490,00 À VISTA</S.FullPrice>
        </S.PriceWrapper>
        <S.CtaButton onClick={handleOpenModal}>
          Inscreva-se agora <FaArrowRight />
        </S.CtaButton>
        {isModalOpen && <Modal onClose={handleCloseModal} />}
      </S.BuyWrapper>
    </S.Content>
  );
};

export default BuyBlock;
