import { createClient } from '@supabase/supabase-js';
import React, { useEffect, useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BsSendCheck } from 'react-icons/bs';
import { IoCloseOutline } from 'react-icons/io5';
import { LuSmartphone } from 'react-icons/lu';
import { MdDataSaverOn, MdOutlineMailOutline } from 'react-icons/md';
import InputMask from 'react-input-mask';
import * as S from './styles';

const supabase = createClient(
  'https://nmvbnvfodqcgqsnffidw.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5tdmJudmZvZHFjZ3FzbmZmaWR3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTE5ODU4NzMsImV4cCI6MjAyNzU2MTg3M30.xp2ER7qmnedJjsI2B78VsjdBgoPep85PW91qOM7AvuA',
);

const Modal = ({ onClose }) => {
  const [isActive, setIsActive] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  const handleClose = () => {
    setIsExiting(true); // Inicia a animação de saída
    setTimeout(() => onClose(), 500); // Aguarda a animação de saída
  };

  useEffect(() => {
    setIsActive(true);
  }, []);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const leadData = { nome, telefone, email, cpf };

    try {
      const { error } = await supabase.from('presave').insert([leadData]);
      if (error) throw error;

      // Sucesso: Avança para a tela de agradecimento
      setStep(2);
    } catch (err) {
      setError('Erro ao salvar seus dados. Tente novamente.');
      console.error('Error submitting lead data:', err.message);
    }
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <S.Backdrop onClick={handleBackdropClick}>
      <S.Form
        onSubmit={handleSubmit}
        onClick={(e) => e.stopPropagation()} // Impede que o evento de clique se propague para o Backdrop
        className={`${isActive ? 'isActive' : ''} ${
          isExiting ? 'isExiting' : ''
        }`}
        onAnimationEnd={() => {
          if (isExiting) onClose();
        }}
      >
        <S.CloserButton onClick={handleClose}>
          <IoCloseOutline />
        </S.CloserButton>
        {step === 1 && (
          <>
            <S.InputWrapper>
              <S.IconWrapper>
                <AiOutlineUser />
              </S.IconWrapper>
              <S.Input
                type='text'
                placeholder='Nome'
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                required
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <S.IconWrapper>
                <LuSmartphone />
              </S.IconWrapper>
              <InputMask
                mask='(99) 99999-9999'
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                placeholder='Telefone'
                required
              >
                {(inputProps) => <S.Input {...inputProps} type='tel' />}
              </InputMask>
            </S.InputWrapper>

            <S.InputWrapper>
              <S.IconWrapper>
                <MdOutlineMailOutline />
              </S.IconWrapper>
              <S.Input
                type='email'
                placeholder='E-mail'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <S.IconWrapper>
                <MdOutlineMailOutline />
              </S.IconWrapper>
              <InputMask
                mask='999.999.999-99'
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                placeholder='CPF'
                required
              >
                {(inputProps) => <S.Input {...inputProps} />}
              </InputMask>
            </S.InputWrapper>

            <S.SendButton type='submit'>
              Fazer Pré-Inscrição <MdDataSaverOn />
            </S.SendButton>
            {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
          </>
        )}

        {step === 2 && (
          <>
            <S.ThankYouMessage>
              <BsSendCheck size='50' />
              Obrigado por fazer sua pré-inscrição no Masterclass Audiovisual na
              Música.
            </S.ThankYouMessage>
            <S.Subtitle>
              Em breve entraremos em contato com você, fique atento ao seu
              e-mail e em seu WhatsApp.
            </S.Subtitle>
          </>
        )}
      </S.Form>
    </S.Backdrop>
  );
};

export default Modal;
