import styled from 'styled-components';
export const SkillsWrapper = styled.div`
  background-color: #000; // Use the appropriate background color
  color: #fff;
  width: 100%;
  position: sticky;
  top: 90px;

  @media (max-width: 768px) {
    position: relative;

    top: 20px;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 0em;

  @media (max-width: 768px) {
    padding: 0 2em;
  }
`;

export const SubHeader = styled.div`
  font-size: 14px;
  margin-bottom: 2em;
  text-transform: uppercase;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Title = styled.h1`
  font-size: 28px; // Adjust size as needed based on the image
  text-align: left;
  margin: 0;
  padding: 0;
  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    margin: 0;
    margin-top: 1em;
  }
`;

export const SkillItem = styled.div`
  display: flex;
  align-items: center;
  border-radius: 120px;
  background: linear-gradient(180deg, #ffffff10 0%, #ffffff30 100%);
  border-radius: 140px;
  margin-bottom: 10px;
  padding: 0;
  position: relative;
  height: 70px;
  color: #fff;

  @media (max-width: 768px) {
    height: 65px;
  }
`;

export const IconWrapper = styled.div`
  font-size: 25px;
  position: absolute;
  top: 0;
  left: 0;
  gap: 1em;
  z-index: 2;
  height: 65px;
  transition: width 2s ease-in-out; // Ajuste o tempo conforme necessário
  width: 50px; /* Adjust this width as needed */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1em;
  border-radius: 120px;
  background: linear-gradient(94deg, #ff302e 5.08%, #ff0000 98.14%);
  box-shadow: 1.155px 2.309px 9.237px 0px rgba(64, 64, 64, 0.42);
  background-blend-mode: overlay;
  font-size: 15px;

  svg {
    font-size: 28px;
    color: #fff;

    @media (max-width: 768px) {
      font-size: 23px;
    }
  }

  span {
    font-size: 14px;
    font-family: 'Unbounded', sans-serif;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 10px;
    }
  }

  @media (max-width: 768px) {
    height: 60px;
  }
`;

export const Label = styled.span`
  flex-grow: 1;
  color: #fff;

  font-size: 24px;
  height: 70px;
  width: 90%;
  transition: width 2s ease-in-out; // Ajuste o tempo conforme necessário
  padding-right: 1em;
  border-radius: 120px;
  background: linear-gradient(180deg, #a90000 0%, #550a0a 100%);
  box-shadow: 4.618px 2.309px 9.237px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  left: 0;

  z-index: 1;

  span {
  }
  svg {
    font-size: 30px;
    margin-left: 0.3em;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    height: 60px;
  }
`;

export const ProgressIndicator = styled.div`
  width: 40px; // Adjust width based on the image
  height: 20px; // Adjust height based on the image
  background-color: #0f0; // Use the appropriate color based on the image
  border-radius: 10px; // Adjust as needed
`;

export const ProgressBase = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 140px;
  background: linear-gradient(
    180deg,
    #373737 0%,
    #222 100%
  ); // Gray base for foundational knowledge
  position: absolute;
  top: 0;
  left: 0;
  transition: width 1s ease-in-out; // Ajuste o tempo conforme necessário
  z-index: 0;
`;

export const ProgressBar = styled.div`
  height: 100%;
  border-radius: 140px;
  background: linear-gradient(
    180deg,
    #ff0000 0%,
    #550a0a 100%
  ); // Red progress bar for learning
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: width 3s ease-in-out; // Ajuste o tempo conforme necessário
`;
