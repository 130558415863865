import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import Modal from './Modal'; // Certifique-se de que o caminho esteja correto
import * as S from './styles';

const Subscribe = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Função para abrir o modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Função para fechar o modal (pode incluir lógica de redirecionamento)
  const handleCloseModal = (isSuccess) => {
    setIsModalOpen(false);
    if (isSuccess) {
      const stripeUrl =
        process.env.REACT_APP_STRIPE_URL ||
        'https://book.stripe.com/dR603x0MX2RW2ME4gg'; // Fallback URL, se necessário
      window.location.href = stripeUrl;
    }
  };

  return (
    <S.Content>
      <S.BuyWrapper id={id}>
        <S.Date>25 e 26 de maio</S.Date>
        <S.Title>
          Imersão presencial<br></br>em São Paulo
        </S.Title>
        <S.SubtitleWhite>
          Inscreva-se agora e garanta<br></br>sua vaga na próxima turma
        </S.SubtitleWhite>
        <S.CtaButton onClick={handleOpenModal}>
          Pré-reserva <FaArrowRight />
        </S.CtaButton>
        {isModalOpen && <Modal onClose={handleCloseModal} />}
      </S.BuyWrapper>
    </S.Content>
  );
};

export default Subscribe;
