import styled from 'styled-components';

export const FaqWrapper = styled.div`
  color: #fff;

  width: 100%;
  font-family: 'Arial', sans-serif;
`;

export const Title = styled.h2`
  font-size: 27px;
  margin-bottom: 30px;
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;

    font-size: 17px;
    line-height: 21px;
  }
`;

export const FaqItem = styled.div``;

export const Question = styled.span`
  font-size: 16px;
  font-weight: medium;
  cursor: pointer;
  border-radius: 21px;
  margin-bottom: 1em;
  display: flex;
  background-color: #101010;
  padding: 2em 2em;
  justify-content: space-between;
  align-items: center;
`;

export const Answer = styled.p`
  font-size: 16px;
  color: #aaa;
  line-height: 1.5;
  padding: 1em 2em 0;
  margin-top: 10px;
`;

// Additional styles for icons if needed
