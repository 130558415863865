import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import * as S from './styles';
const faqData = [
  {
    question: 'Não sei se o curso é pra mim.',
    answer:
      'Aprendendo na prática a desenvolver ícones e símbolos seguindo uma mesma linguagem visual de forma que você nunca mais precisará recorrer a bancos de ícones para compor suas interfaces.',
    isOpen: false,
  },
  {
    question: 'Já sei um pouco sobre o assunto',
    answer:
      'Ótimo! O curso é estruturado para levar os participantes de um nível intermediário a avançado, então seu conhecimento prévio será uma excelente base para os módulos mais avançados.',
    isOpen: false,
  },
  {
    question: 'O acesso é vitalício?',
    answer:
      'Sim, ao se inscrever no curso, você terá acesso vitalício ao conteúdo e atualizações, podendo aprender no seu próprio ritmo.',
    isOpen: false,
  },
  {
    question: 'Devo começar pelo Moysah?',
    answer:
      'Não é obrigatório, mas recomendamos começar pelo Moysah para uma compreensão abrangente dos fundamentos antes de avançar para técnicas mais complexas.',
    isOpen: false,
  },
  {
    question: 'Tem certificado?',
    answer:
      'Sim, todos os alunos que completarem o curso receberão um certificado de conclusão para atestar suas novas habilidades.',
    isOpen: false,
  },
  {
    question: 'Quando abrem as inscrições?',
    answer:
      'As inscrições abrem a cada trimestre, e recomendamos que você se inscreva na nossa lista de espera para ser notificado assim que abrirem.',
    isOpen: false,
  },
  // ... other questions as needed
];

const Faq = ({ id }) => {
  const [faqs, setFaqs] = useState(faqData);

  const toggleFaq = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.isOpen = !faq.isOpen;
        }
        return faq;
      }),
    );
  };

  return (
    <S.FaqWrapper id={id}>
      <S.Title>DÚVIDAS FREQUENTES</S.Title>
      {faqs.map((faq, index) => (
        <S.FaqItem key={index}>
          <S.Question onClick={() => toggleFaq(index)}>
            {faq.question}
            {faq.isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </S.Question>
          {faq.isOpen && <S.Answer>{faq.answer}</S.Answer>}
        </S.FaqItem>
      ))}
    </S.FaqWrapper>
  );
};

export default Faq;
