import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
   @import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');

  :root {
    --foreground-rgb: 255, 255, 255; 
    --background-start-rgb: 000, 000, 000; 
    --background-end-rgb:  000, 000, 000; 

  }

  @media (prefers-color-scheme: dark) {
    :root {
      --foreground-rgb: 255, 255, 255;
      --background-start-rgb: 000, 000, 000; 
      --background-end-rgb: 000, 000, 000; 
    }
  }

  * {
    box-sizing: border-box;
  }

  body {
    color: rgb(var(--foreground-rgb));
    background: rgb(var(--background-start-rgb));
    width: 100%;
    margin: 0;
    font-family: 'Unbounded', sans-serif; 
  }

  span, p, li, a { 
    font-family: 'Arial', sans-serif; 
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Unbounded', sans-serif; 
  }


  .-no-overflow {
    overflow: hidden;
  }


  input:-internal-autofill-selected {
    background-color: transparent !important;
    background-image: none !important;
    color: rgb(var(--foreground-rgb)) !important;
    background: transparent !important;
  }
`;
