import styled from 'styled-components';

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #000;
  align-items: center;
  justify-content: center;
  padding: 3em 2em 3em;
  gap: 1em;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 3em;
  }
`;

export const IconSanches = styled.img``;

export const Logo = styled.div`
  display: flex;
  color: #fff;
  align-items: center;
  gap: 1em;
  justify-content: flex-start;

  svg {
    width: 24.099px;
    height: 34.409px;
    flex-shrink: 0;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding: 1em 0;
    justify-content: center;
  }
`;

export const StartProject = styled.button`
  border: none;
  font-size: 15px;

  color: #434957;
  width: 322px;
  height: 68px;
  border-radius: 12px;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.9;
    background-color: #434957;
    color: #f1ffee;
  }

  svg {
    font-size: 22px;
  }
`;

export const SocialMedias = styled.div`
  width: 100%;
  display: flex;
  width: 235px;
  height: 68px;

  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 12px;
  gap: 2em;
  transition: all 0.3s ease;

  svg {
    transition: all 0.3s ease;
    fill: #fff;
    font-size: 21px;
    &:hover {
      fill: #f1ffee;
      opacity: 0.9;
      scale: 1.1;
    }
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #ffffff50;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: opacity 400ms ease;
  opacity: 0;
  pointer-events: none;
`;

export const ModalContent = styled.div`
  background: #434957;
  padding: 0;
  color: #f1ffee;
  width: 50%;
  border-radius: 21px 0 0 21px;
  height: 100%;
  overflow: hidden;
  transform: translateX(100%);
  transition: transform 400ms ease;
  z-index: 30;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 20px;
  top: 35px;
  cursor: pointer;

  svg {
    fill: #fff;
    font-size: 32px;
    color: #fff;
  }

  &:hover {
    opacity: 0.6;
    svg {
      opacity: 0.6;
    }
  }
`;

export const MenuListing = styled.div`
  padding: 4em;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
