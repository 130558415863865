import styled, { keyframes } from 'styled-components';

export const CloserButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
  color: #00000030;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  z-index: 10;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3em;

  width: 90%;
  margin-top: 1em;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const animatedBorderAndText = keyframes`
  0% { border-color: #FFD700; color: #FFD700; } /* Amarelo */
  50% { border-color: #e50914; color: #e50914; } /* Verde */
  100% { border-color: #FFD700; color: #FFD700; } /* Amarelo */
`;

export const Date = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0;

  color: #ffd700; /* Amarelo */
  animation: ${animatedBorderAndText} 2s infinite;
  font-family: 'Unbounded', sans-serif;
`;

// Aplicando as animações ao BuyWrapper
export const BuyWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-top: 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  color: #fff;
  padding: 0em;
  text-align: center;
  border-radius: 28px;
`;

// Ajustando a animação do título para sincronizar com a borda
export const Title = styled.h2`
  text-transform: uppercase;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 22px;
  }
`;

export const PriceWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
`;

export const OldPrice = styled.p`
  text-decoration: line-through;
  color: #999;
  margin: 0;
  font-size: 18px;
`;

export const NewPriceWrapper = styled.div`
  padding: 0px;
  gap: 1em;
  display: flex;
  align-items: center;
`;

export const NewPriceLabel = styled.span`
  font-size: 18px;
  text-transform: uppercase;
  background: #e50914;
`;

export const NewPrice = styled.h3`
  font-size: 54px;
  font-weight: bold;

  margin: 0;
  margin-left: 5px;
  display: flex;
  gap: 0.2em;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 400;
    margin-right: -1.4em;
  }
`;

export const FullPrice = styled.p`
  font-size: 18px;
  margin: 0;
`;

export const TextIcon = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const CtaButton = styled.button`
  background-color: #e50914;
  color: #fff;
  border: 1px solid #e50914;
  margin-top: 2em;
  border-radius: 50px;
  padding: 18px 27px;
  font-size: 13px;
  gap: 2.3em;
  font-weight: bold;
  cursor: pointer;

  display: flex;
  align-items: center;
  width: 20%;
  justify-content: space-between;
  text-transform: uppercase;
  transition: all 0.3s ease;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.11);
  font-family: 'Unbounded', sans-serif;

  p {
    margin: 0;
  }

  &:hover,
  &:focus {
    background-color: #e5091480;
    border: 1px solid #e50914;
    transform: scale(1.05);
    animation: ${pulseAnimation} 1.5s infinite ease-in-out;
  }

  svg {
    margin: 0;
  }

  @media (max-width: 768px) {
    width: 60%;
  }
`;

export const PaymentMethods = styled.div`
  svg {
    margin: 0 5px;
    color: #fff;
    font-size: 24px;
  }
`;

// MODAL
const fadeInUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeOutDown = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  border: 4px solid #ffffff;
  border-radius: 21px;
  gap: 5px;
  width: 25%;
  align-items: center;
  position: absolute;
  background-color: #ffffff;
  backdrop-filter: blur(15px);
  padding: 2em;

  @media (max-width: 768px) {
    width: 80%;
  }
  &.isActive {
    animation: ${fadeInUp} 0.5s ease forwards;
  }
  &.isExiting {
    animation: ${fadeOutDown} 0.5s ease forwards;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 2px solid #ffff;
  border-radius: 15px;
  padding: 0 0 0 1em;
  height: 50px;
  color: #000;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const ErrorMessage = styled.div`
  color: #e50914;
  font-size: 16px;
  margin-top: 5px;
`;

export const Input = styled.input`
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 10px;
  color: #000;
  flex: 1;
  border-radius: 0 15px 15px 0;
  background-color: transparent;
  font-size: 16px;

  &:focus {
    box-shadow: 0 0 0 2px #007bff;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #e50914;
`;

export const SendButton = styled.button`
  width: 100%;
  text-align: center;
  background: #e50914;
  border: 2px solid #e50914;
  color: #fff;
  border-radius: 50px;
  padding: 12px 27px;
  margin-top: 10px;
  font-size: 12px;
  gap: 1.3em;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-family: 'Unbounded', sans-serif;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    background-color: #e50914;
    transform: scale(1.05);
    animation: ${pulseAnimation} 1.5s infinite ease-in-out;
  }

  svg {
    margin: 0;
    font-size: 24px;
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
export const LabelOption = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 11px;
  color: ${(props) =>
    props.isSelected ? '#fff' : '#fff'}; // Change colors as needed
  font-weight: ${(props) => (props.isSelected ? '500' : 'normal')};
  background-color: ${(props) =>
    props.isSelected
      ? '#e50914'
      : '#ffffff30'}; // Example background color change
  padding: 12px 20px;
  border-radius: 120px;
  transition: all 0.3s ease;

  svg {
    font-size: 20px;
  }

  &:hover {
    background-color: ${(props) =>
      props.isSelected ? '#e5091430' : '#e5091490'}; // Example hover effect
  }
`;

export const Option = styled.input`
  display: none;
`;

export const QRCodeWarpper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 3em 1.5em;
  align-items: center;

  svg {
    font-size: 60px;
  }
`;

export const QRCodeImage = styled.img`
  width: 100%;
  max-width: 200px;
  height: auto;
`;

export const ConfirmButton = styled.button`
  background-color: #e50914;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 12px 27px;
  font-size: 12px;
  gap: 2.3em;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  font-family: 'Unbounded', sans-serif;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    background-color: #e50914;
    transform: scale(1.05);
    animation: ${pulseAnimation} 1.5s infinite ease-in-out;
  }

  svg {
    margin: 0;
  }
`;

export const ConfirmButtonGreen = styled.button`
  background-color: #e50914;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 12px 27px;
  font-size: 12px;
  gap: 2.3em;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Unbounded', sans-serif;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    background-color: #e50914;
    transform: scale(1.05);
    animation: ${pulseAnimation} 1.5s infinite ease-in-out;
  }

  svg {
    margin: 0;
    font-size: 24px;
  }
`;
export const PaymentOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px; // Ajuste o espaçamento conforme necessário
  padding: 20px;
`;

export const Heading = styled.h2`
  font-size: 25px; // Ajuste o tamanho conforme necessário
  font-weight: bold;
  line-height: 30px;
  font-family: 'Helvetica' sans-serif;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 21px;
    line-height: 21px;
  }
`;

export const BankImage = styled.img`
  width: 100px; // Ajuste o tamanho conforme necessário
  height: auto;
`;

export const PaymentOptionButton = styled.button`
  background-color: ${(props) =>
    props.brand === 'NUBANK'
      ? '#8A05BE'
      : '#FFC801'}; // Roxo para Nubank, Amarelo para PagSeguro
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 120px;
  cursor: pointer;
  font-family: 'Unbounded', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin: 10px 0; // Ajuste a margem conforme necessário

  &:hover {
    opacity: 0.9;
  }
`;

export const RedirectConfirmation = styled.div`
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const FinalScreen = styled.div`
  text-align: center;
  padding: 20px;
`;

export const WhatsAppButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #25d366;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;

  &:hover {
    background-color: #128c7e;
  }
`;

export const CopyToClipboardButton = styled.button`
  background-color: transparent; // Verde
  border: 1px solid #fff;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 120px;
  font-weight: bold;
  font-family: 'Unbounded', sans-serif;
  text-transform: uppercase;
  margin-top: 10px;

  svg {
    margin-right: 5px;
    font-size: 20px;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export const Subtitle = styled.p`
  font-size: 15px;
  line-height: 21px;
  margin: 0;
  margin-top: 20px;
  color: #000;
  font-family: 'Helvetica' sans-serif;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 14px;
  }
`;

export const SubtitleWhite = styled.p`
  font-size: 15px;
  line-height: 21px;
  margin: 0;
  margin-top: 20px;
  color: #fff;
  font-family: 'Helvetica' sans-serif;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 14px;
  }
`;
export const ThankYouMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  text-align: center;
  color: black;

  svg {
    margin-bottom: 20px;
    color: #4caf50; // ou qualquer cor que você preferir
  }
`;

export const Backdrop = styled.div`
  position: fixed; // Usa fixed para cobrir toda a tela
  top: 0;
  left: 0;
  width: 100vw; // Cobrindo toda a largura da viewport
  height: 100vh; // Cobrindo toda a altura da viewport
  background-color: rgba(0, 0, 0, 0.5); // Cor preta com transparência
  z-index: 999; // Certifique-se de que está atrás do modal mas acima de outros elementos
  display: flex;
  justify-content: center;
  align-items: center; // Isso centraliza o modal se você colocar o Form dentro do Backdrop
`;
