import styled, { css, keyframes } from 'styled-components';

// Utilitários
const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
};

// Media queries helper
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

// Layout & Estrutura
export const ModulesWrapper = styled.div`
  background-color: #000;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 20px;
  text-align: left;

  padding: 0 0em;

  @media (max-width: 768px) {
    padding: 0 2em;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  margin-bottom: 40px;
`;

// Botões
const ButtonBase = styled.button`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 1em;
  border-radius: 20px;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  ${media.tablet`padding: 0.6em 1.2em; font-size: 1.1rem;`}
`;

export const PlayButton = styled(ButtonBase)`
  background: rgba(255, 255, 255, 0.25);
  color: #fff;
  margin: 18px auto 40px;
  gap: 14px;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`;

export const CtaButton = styled(ButtonBase)`
  background-color: #e50914;
  color: #fff;
  width: 100%;
  margin-top: 1em;

  &:hover {
    background-color: darken(#e50914, 10%);
  }
`;

export const CtaSubButton = styled(CtaButton)`
  background-color: transparent;
  color: #fff;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

// Modais
const modalAnimation = keyframes`
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
`;

export const ModalContent = styled.div`
  background: #707070;
  border-radius: 21px;
  padding: 30px;
  max-width: 500px;
  width: 95%;
  animation: ${modalAnimation} 0.3s ease-out;
`;

// Títulos & Textos
export const Header = styled.h1`
  font-size: 28px; // Adjust size as needed based on the image
  text-align: left;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    margin: 0;
  }
`;

export const SubHeader = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

// Itens & Conteúdo
export const ModuleItem = styled.div`
  background: ${({ isActive }) =>
    isActive ? 'rgba(63, 63, 63, 0.80)' : 'rgba(63, 63, 63, 0.49)'};
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  padding: 24px;
  min-width: 280px;
  width: 100%;
  gap: 5px;
  transition: background-color 0.3s ease;

  ${media.tablet`padding: 30px 24px 18px;`}
  ${media.phone`padding: 24px;`}
`;

// Responsividade adicional e ajustes
// Adicione mais estilos específicos para mobile, tablet e desktop conforme necessário

// ModuleTitle
export const ModuleTitle = styled.span`
  display: flex;
  align-items: center;

  font-size: 14px; // ajuste conforme necessário
  color: #fff;
  font-weight: bold;
  display: flex;
  border-radius: 15px;
  gap: 1em;
  margin-bottom: 8px;
  font-family: 'Unbounded', sans-serif;

  svg {
    margin: 0;
    font-size: 20px;
  }

  span {
    background-color: red;
    font-family: 'Unbounded', sans-serif;
    padding: 0.3em 0.8em;
    background-color: #000;
    font-size: 14px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 0.8em;

    @media (max-width: 768px) {
      font-size: 10px;
      width: fit-content;
      margin: 0;
      height: fit-content;

      svg {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 768px) {
    font-size: 14px;
    flex-direction: column;
    gap: 0.5em;

    align-items: flex-start;
    justify-content: flex-start;
  }
`;

// MoreIcon
export const MoreIcon = styled.div`
  cursor: pointer;
  gap: 0.5em;
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: #00000078;
  position: absolute;
  padding: 1.3em 2em;
  bottom: 0;
  right: 0;
  border-radius: 28px 0px 20px 0px;

  svg {
    font-size: 20px;
    color: #fff;
  }

  transition: all 0.3s ease;

  &:hover {
    background: #fff;

    svg {
      color: #000;
    }
  }
`;

// ModuleContent
export const ModuleContent = styled.span`
  font-size: 14px; // ajuste conforme necessário
  color: #fff;
  margin-top: 10px;
  text-align: left;
  display: block; // ou 'flex' dependendo da necessidade
`;

// Description
export const Description = styled.div`
  color: #fff;
  font-size: 17px;
  text-align: left;
  height: 90px;
  margin-top: 0.4em;
  font-family: 'Arial', sans-serif;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
`;
